var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CCard',{attrs:{"border-color":_vm.cardClass}},[(_vm.pp)?_c('CCardHeader',[_c('div',{staticClass:"card-header-actions text-right"},[(_vm.pp.ext__lots_active > 0)?_c('div',{staticClass:"mt-2"},[(_vm.pp.ext__price_min == _vm.pp.ext__price_max)?_c('b',{class:_vm.titleClass},[_vm._v(_vm._s(_vm.pp.ext__price_max)+" CHF")]):_c('b',{class:_vm.titleClass},[_vm._v(_vm._s(_vm.pp.ext__price_min)+" - "+_vm._s(_vm.pp.ext__price_max)+" CHF")])]):_vm._e()]),_c('div',{staticStyle:{"display":"flex","flex-direction":"row"}},[_c('div',{staticClass:"mr-4 mt-2"},[(!_vm.active_loading)?_c('CSwitch',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip.hover",value:({
            content: _vm.$t('role.products.showcase.product.btn_activate'),
          }),expression:"{\n            content: $t('role.products.showcase.product.btn_activate'),\n          }",modifiers:{"hover":true}}],attrs:{"color":"success","data-on":"On","data-off":"Off","checked":_vm.pp.is_active},on:{"update:checked":function($event){return _vm.$set(_vm.pp, "is_active", $event)}}}):_c('CSpinner',{attrs:{"color":"success","size":"sm"}})],1),_c('div',[_c('b',{class:_vm.titleClass},[_vm._v(_vm._s(_vm.pp.translations[_vm.cur_lang].name))]),_c('br'),_vm._v(" PP"+_vm._s(_vm.pp.id)+" - "+_vm._s(_vm.$t("role.products.showcase.product.lots_active"))+" "+_vm._s(_vm.pp.ext__lots_active)+", "+_vm._s(_vm.$t("role.products.showcase.product.lots_inactive"))+" "+_vm._s(_vm.pp.ext__lots_inactive)+" ")])])]):_vm._e(),_c('CCardBody',[_c('CRow',[_c('CCol',{attrs:{"md":"4"}},[_c('router-link',{attrs:{"to":'/role/' + _vm.$store.state.role.id + '/products/products/pp_' + _vm.pp.id}},[(_vm.pp.image_cropped)?_c('img',{staticClass:"img-fluid",attrs:{"src":_vm.pp.image_cropped}}):_vm._e()]),_c('CRow',_vm._l((_vm.certifications),function(cert,index){return _c('CCol',{key:index,attrs:{"md":"4"}},[(cert.thumb)?_c('img',{staticClass:"img-fluid",attrs:{"src":cert.thumb}}):_c('span',[_vm._v(_vm._s(cert.label))])])}),1)],1),_c('CCol',{attrs:{"md":"8"}},[_c('p',[_vm._v(" "+_vm._s(_vm.$t("role.products.showcase.product.category"))+": "),_c('b',[_vm._v(_vm._s(_vm.macrocategory)+" > "+_vm._s(_vm.parent)+" > "+_vm._s(_vm.category))])]),_c('p',[_vm._v(" "+_vm._s(_vm.$t("role.products.showcase.product.age_class"))+": "),_c('b',[_vm._v(_vm._s(_vm.ageclass))])]),_c('p',[_vm._v(" "+_vm._s(_vm.$t("role.products.showcase.product.logistics"))+": "),_c('b',[_vm._v(_vm._s(_vm.ptype))])]),_c('div',{domProps:{"innerHTML":_vm._s(_vm.pp.translations[_vm.cur_lang].description)}}),_c('div',{staticClass:"text-right"},[_c('CButton',{attrs:{"color":"secondary","to":'/role/' +
              _vm.$store.state.role.id +
              '/products/products/pp_' +
              _vm.pp.id}},[_vm._v(" "+_vm._s(_vm.$t("common.editor.button.edit"))+" ")]),_c('CButton',{attrs:{"color":"primary","to":{
              name: 'RoleProductsEdit',
              params: { id: _vm.$store.state.role.id, product_id: _vm.pp.id },
            }}},[_vm._v(" edit NEW ")])],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }