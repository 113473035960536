<template>
  <CCard :border-color="cardClass">
    <CCardHeader v-if="pp">
      <div class="card-header-actions text-right">
        <div v-if="pp.ext__lots_active > 0" class="mt-2">
          <b :class="titleClass" v-if="pp.ext__price_min == pp.ext__price_max"
            >{{ pp.ext__price_max }} CHF</b
          >
          <b :class="titleClass" v-else
            >{{ pp.ext__price_min }} - {{ pp.ext__price_max }} CHF</b
          >
        </div>
      </div>
      <div style="display: flex; flex-direction: row">
        <div class="mr-4 mt-2">
          <CSwitch
            v-if="!active_loading"
            color="success"
            data-on="On"
            data-off="Off"
            :checked.sync="pp.is_active"
            v-c-tooltip.hover="{
              content: $t('role.products.showcase.product.btn_activate'),
            }"
          />
          <CSpinner v-else color="success" size="sm" />
        </div>
        <div>
          <b :class="titleClass">{{ pp.translations[cur_lang].name }}</b>
          <br />
          PP{{ pp.id }} -
          {{ $t("role.products.showcase.product.lots_active") }}
          {{ pp.ext__lots_active }},
          {{ $t("role.products.showcase.product.lots_inactive") }}
          {{ pp.ext__lots_inactive }}
        </div>
      </div>
    </CCardHeader>
    <CCardBody>
      <CRow>
        <CCol md="4">
          <router-link
            :to="
              '/role/' + $store.state.role.id + '/products/products/pp_' + pp.id
            "
          >
            <img
              v-if="pp.image_cropped"
              v-bind:src="pp.image_cropped"
              class="img-fluid"
            />
          </router-link>
          <CRow>
            <CCol md="4" v-for="(cert, index) in certifications" :key="index">
              <img
                v-if="cert.thumb"
                v-bind:src="cert.thumb"
                class="img-fluid"
              />
              <span v-else>{{ cert.label }}</span>
            </CCol>
          </CRow>
        </CCol>
        <CCol md="8">
          <p>
            {{ $t("role.products.showcase.product.category") }}:
            <b>{{ macrocategory }} > {{ parent }} > {{ category }}</b>
          </p>
          <p>
            {{ $t("role.products.showcase.product.age_class") }}:
            <b>{{ ageclass }}</b>
          </p>
          <p>
            {{ $t("role.products.showcase.product.logistics") }}:
            <b>{{ ptype }}</b>
          </p>
          <div v-html="pp.translations[cur_lang].description" />
          <div class="text-right">
            <CButton
              color="secondary"
              :to="
                '/role/' +
                $store.state.role.id +
                '/products/products/pp_' +
                pp.id
              "
            >
              {{ $t("common.editor.button.edit") }}
            </CButton>
            <CButton
              color="primary"
              :to="{
                name: 'RoleProductsEdit',
                params: { id: $store.state.role.id, product_id: pp.id },
              }"
            >
              edit NEW
            </CButton>
          </div>
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
import { GetDataManager } from "../../../ds/index";
import { Query } from "@syncfusion/ej2-data";
import GetOptions from "../../../common/form/options";
export default {
  data() {
    return {
      cur_lang: this.$i18n.locale,
      pp_show_detail: false,
      active_loading: false,
      pp: this.bpp,
      macrocategory: null,
      parent: null,
      category: null,
      ageclass: null,
      ptype: null,
      certifications: [],
    };
  },
  model: {
    prop: "bpp",
    event: "change",
  },
  props: ["bpp"],
  created() {
    let self = this;
    GetOptions({
      dm: GetDataManager("structure_ptype"),
      query_cache: "structure_ptype",
      cur_lang: this.cur_lang,
    }).then((opts) => {
      this.macrocategory = opts.reduce(function (map, obj) {
        if (obj.value == self.pp.category__macrocategory_id) {
          map = obj.label;
        }
        return map;
      }, "");
    });
    GetOptions({
      dm: GetDataManager("structure_category_browser"),
      query: new Query().where("parent_id", "equal", null),
      query_cache: "structure_category_browser_p",
      cur_lang: this.cur_lang,
    }).then((opts) => {
      this.parent = opts.reduce(function (map, obj) {
        if (obj.value == self.pp.category__parent_id) {
          map = obj.label;
        }
        return map;
      }, "");
    });
    GetOptions({
      dm: GetDataManager("structure_category_browser"),
      query: new Query().where("parent_id", "greaterthan", 0),
      query_cache: "structure_category_browser_c",
      cur_lang: this.cur_lang,
    }).then((opts) => {
      this.category = opts.reduce(function (map, obj) {
        if (obj.value == self.pp.category_id) {
          map = obj.label;
        }
        return map;
      }, "");
    });
    GetOptions({
      dm: GetDataManager("structure_ageclass"),
      query_cache: "structure_ageclass",
      cur_lang: this.cur_lang,
    }).then((opts) => {
      this.ageclass = opts.reduce(function (map, obj) {
        if (obj.value == self.pp.ageclass_id) {
          map = obj.label;
        }
        return map;
      }, "");
    });
    GetOptions({
      dm: GetDataManager("structure_ltype"),
      query: new Query(),
      query_cache: "structure_ltype",
      cur_lang: this.cur_lang,
    }).then((opts) => {
      this.ptype = opts.reduce(function (map, obj) {
        if (obj.value == self.pp.ptype_id) {
          map = obj.label;
        }
        return map;
      }, "");
    });
    GetOptions({
      dm: GetDataManager("structure_certification"),
      reducer: function (map, obj) {
        map.push({ value: obj.id, label: obj.name, thumb: obj.image_thumb });
        return map;
      },
      reducer_cache: "certifications_i",
    }).then((opts) => {
      this.certifications = opts.reduce(function (map, obj) {
        if (self.pp.certifications.includes(obj.value)) {
          map.push({
            thumb: obj.thumb,
            label: obj.label,
          });
        }
        return map;
      }, []);
    });
  },
  computed: {
    titleClass() {
      if (this.bpp.is_active == true) {
        return "product_name";
      } else {
        return "product_disabled";
      }
    },
    cardClass() {
      if (this.active_loading) {
        return "secondary";
      } else if (this.bpp.is_active == true) {
        return "success";
      } else {
        return "danger";
      }
    },
  },
  watch: {
    bpp: function () {
      this.pp = this.bpp;
    },
    "pp.is_active": function (Nv, Ov) {
      if (typeof Ov !== "undefined") {
        this.active_loading = true;
        let params = {
          id: this.pp.id,
          is_active: this.pp.is_active,
        };
        let self = this;
        GetDataManager("role_producer_products", [this.$store.state.role.id])
          .update("", params)
          .then(
            (e) => {
              //   self.pp = e;
              self.$emit("change", e);

              self.active_loading = false;

              this.$store.dispatch("toaster/add", {
                title: this.$t(
                  "role.products.showcase.product.toast.active.title"
                ),
                text: this.$t(
                  "role.products.showcase.product.toast.active.text"
                ),
                autohide: true,
              });
            },
            () => {
              this.pp.is_active = !this.pp.is_active;
              this.active_loading = false;
              // this.dataForm.errors = errors;
              // this.dataForm.loading = false;
            }
          );
      }
    },
  },
};
</script>

<style scoped>
.product_name {
  font-size: 1.8em;
}
.product_disabled {
  font-size: 1.5em;
  text-decoration: line-through;
  color: grey;
}
</style>
