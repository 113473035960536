import { render, staticRenderFns } from "./ProductsListProduct.vue?vue&type=template&id=7b7469c4&scoped=true&"
import script from "./ProductsListProduct.vue?vue&type=script&lang=js&"
export * from "./ProductsListProduct.vue?vue&type=script&lang=js&"
import style0 from "./ProductsListProduct.vue?vue&type=style&index=0&id=7b7469c4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b7469c4",
  null
  
)

export default component.exports